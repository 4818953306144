.app-header {
  background-color: #222327;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.app-header > .nav {
  display: flex;
  align-items: center;
  margin: 3rem;
}

.App-link {
  color: #61dafb;
}

.mojo-logo {
  text-align: center;
  margin: 0 48px 0 48px;
  width: 100px
}

.app-footer {
  text-align: center;
  margin-top: 3rem;
}

a {
  color: white;
}

a:hover {
  color: #d9dbd9;
}

.naked-link {
  text-decoration: none;
}

.fake-link {
  color: white;
  cursor: pointer;
}

.fake-link:hover {
  color: #dbdbdb;
  cursor: pointer;
}

audio {
  width: 100%;
}

.link-list > a {
  text-decoration: none;
}

.capitalize {
  text-transform: capitalize;
}

div.audio-list {
  max-width: 500px;
  margin-bottom: 1rem;
}

.accordion-summary-title {
  display: flex;
  align-items: center;
  text-align: left;
}

.accordion-summary-title > svg {
  margin-right: 1rem
}

.download-app-qr {
  margin: 1rem 0;
  width: 200px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
