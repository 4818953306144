.profile{
  width: 90%;
  max-width: 400px;
  border: 1px solid #4c4b4b;
  padding: 20px;
  text-align: center;
  margin-top: 3rem;
}

.profile p {
  text-align: left;
}

.profile > span{
  color: blue;
  margin-top: 30px;
  cursor: pointer;
}