.verifyEmail {
  width: 90%;
  max-width: 500px;
  text-align: center;
  padding: 40px 20px;
}

.verifyEmail h1{
  font-weight: 300;
  margin-top: 0;
}

.verifyEmail > p{
  line-height: 25px;
}

.verifyEmail > span{
  color: gray;
}

.verifyEmail > button {
  margin-top: 35px;
}