.auth {
  width: 90%;
  max-width: 425px;
  padding: 20px;
  text-align: center;
}

.auth form{
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto 10px auto;
}

.auth__success{
  padding: 10px 0;
  background-color: hsl(147, 79%, 87%);
  margin-bottom: 20px;
}

.auth__error{
  margin-bottom: 20px;
}

.auth form > input, button{
  height: 40px;
  font-size: 16px;
  padding: 0 10px;
}